<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('announce.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">

      <v-card>
        <v-card-title> {{ $t('announce.head_list2') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-form ref="formAnnounce" class="mt-5">
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field
                  v-model="form.message"
                  :label="$t('announce.create_data')"
                  :rules="formRule.required"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'announce' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3"> {{ $t('back') }}</span>
          </v-btn>
          <v-btn color="success" @click="save" class="px-3">
            <v-icon>mdi-content-save</v-icon>
            <span class="px-3" :loading="loading">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      breadcrumbsItems: [
        {
          text: this.$t('announce.head'),
          disabled: false,
          href: '/news',
        },
        {
          text: this.$t('announce.head_list3'),
          disabled: true,
          href: '',
        },
      ],
      form: {
        message: ''
      },
      formRule: {
        required: [
          value => !!value || 'This field is required.',
        ],
      },
      statusItems: [
        {
          text: this.$t('open'),
          value: 'open',
        },
        {
          text: this.$t('close'),
          value: 'close',
        },
      ],
      loading: false,
    }
  },
  async created() {
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'ประกาศข้อความ',
            url: window.location.href,
            detail: 'เพิ่มประกาศข้อความ',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async save() {
      this.loading = true
      if (this.$refs.formAnnounce.validate()) {
        try {
          function containsEmoji(message) {
            const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
            return emojiRegex.test(message);
          }

          this.form.message = this.form.message.replace(/\s+/g, ' ').trim();

          if(containsEmoji(this.form.message)) {
            this.$swal.fire({
              icon: 'error',
              title: 'ไม่อนุญาตให้ข้อความมี emoji',
            })
          } else if(this.form.message.length === 0) {
            this.$swal.fire({
              icon: 'error',
              title: 'โปรดกรอกข้อความ',
            })
          } else {
            await this.$store.dispatch('addAnnounce', this.form)
            this.$swal.fire(this.$t('announce.alert_complete'), '', 'success')
            this.$router.push({ name: 'announce' })
          }
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message[0],
            })
          }
        }
      }
      this.loading = false
    },
  },
}
</script>
